import {toggleBodyScroll} from "@scripts/_helpers"

const menuToggler = document.getElementById('menuToggler')
const menu = document.querySelector('.header__menu')
const menuMask = document.querySelector('.header__menu-mask')
const menuClose = document.querySelector('.header__menu-close')

const toggleMenu = () => {
    toggleBodyScroll()
    menu.classList.toggle('header__menu_opened')
    menuMask.classList.toggle('site-mask_opened')
}

[menuToggler, menuMask, menuClose].forEach(item => {
    if(!item) return null
    item.addEventListener('click', () => toggleMenu())
})
