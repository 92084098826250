export const toggleBodyScroll = () => {
    const body = document.body
    const enabled = body.style?.paddingRight > 0
    if(enabled){
        const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth
        body.style.overflow = 'hidden'
        body.style.paddingRight = `${scrollbarWidth}px`
    } else {
        body.style.overflow = null
        body.style.paddingRight = null
    }
}