import Swiper from "swiper"
import {EffectFade, Navigation, Pagination} from "swiper/modules"

const siteSliders = document.querySelectorAll('.site-slider')
if(siteSliders.length > 0){
    siteSliders.forEach((slider, index) => {
        index++
        const id = `slider-${index}`
        slider.id = id

        new Swiper(`#${id}`, {
            modules: [EffectFade, Navigation, Pagination],
            effect: 'fade',
            crossFade: true,
            navigation: {
                nextEl: `#${id} .site-slider__arrow-next`,
                prevEl: `#${id} .site-slider__arrow-prev`,
                disabledClass: 'site-slider__arrow-disabled'
            },
            pagination: {
                bulletElement: 'div',
                bulletClass: 'item',
                bulletActiveClass: 'item__active',
                clickable: true,
                el: `#${id} .site-slider__pagination`,
            }
        })
    })
}