import {toggleBodyScroll} from "@scripts/_helpers";

let siteModal = document.querySelector('.modal')
const visibleClass = 'modal_visible'
const toggleModal = () => {
    if(siteModal.classList.contains(visibleClass)){
        siteModal.classList.remove(visibleClass)
        setTimeout(() => toggleBodyScroll(false), 300)
    } else {
        siteModal.classList.add(visibleClass)
        toggleBodyScroll(true)
    }
}

document.querySelectorAll('[data-modal]').forEach(el => {
    el.addEventListener('click', () => {
        toggleModal()
    })
})

siteModal.addEventListener('click', e => {
    if(e.target === siteModal){
        toggleModal()
    }
})

document.querySelector('.modal__close').addEventListener('click', e => {
    toggleModal()
})