import ymaps from 'ymaps'

const initYmaps = () => {
    const mapElement = document.querySelector('#map')
    if(!mapElement) return

    const mapItems = document.querySelectorAll('.contacts__list .item, .points__list .item')
    if(mapItems.length === 0) return

    const firstElementCoordinates = mapItems[0].dataset?.coordinates ?? null
    if(!firstElementCoordinates) return

    ymaps.load(`https://api-maps.yandex.ru/2.1/?lang=ru_RU`)
        .then(maps => {
            const map = new maps.Map(mapElement, {
                center: firstElementCoordinates.split(','),
                zoom: 16,
                controls: ['zoomControl']
            }, {
                maxZoom: 16,
                searchControlProvider: 'yandex#search'
            })

            map.margin.setDefaultMargin(20)

            mapItems.forEach((el, i) => {
                let coordinates = el.dataset?.coordinates ?? null
                if(!coordinates) return
                coordinates = coordinates.split(',')
                let icon = el.dataset?.icon ?? '/map/office.svg'

                const placemark = new maps.Placemark(coordinates, {}, {
                    iconLayout: 'default#image',
                    iconImageHref: icon,
                    iconImageSize: [80, 80],
                    iconImageOffset: [-40, -72]
                })

                el.addEventListener('click', () => {
                    let current = document.querySelector('.contacts__list .item.selected')
                    if(current) current.classList.remove('selected', 'opened')
                    el.classList.add('selected', 'opened')
                    if(current === el){
                        el.classList.remove('selected', 'opened')
                        map.setCenter(firstElementCoordinates.split(','))
                        map.setBounds(map.geoObjects.getBounds(), { useMapMargin: true })
                        map.container.fitToViewport()
                    } else {
                        map.setCenter(coordinates)
                        map.setZoom(16)
                    }
                })

                map.geoObjects.add(placemark)
            })

            map.setBounds(map.geoObjects.getBounds(), { useMapMargin: true })
            map.container.fitToViewport()
        })
}

initYmaps()