import axios from "axios";

const headerSearchInput = document.querySelector('.header__search-input')

if(headerSearchInput){
    headerSearchInput.addEventListener('focus', () => {
        const dataUrl = headerSearchInput.dataset?.url ?? null

        if(dataUrl){
            let data

            axios.get(dataUrl)
                .then(result => {
                    data = result.data

                    const headerSearch = document.querySelector('.header__search')

                    headerSearchInput.addEventListener('input', e => {
                        const items = data.filter(item => item.name.includes(e.target.value) === true)
                        console.log(items)
                    })
                })
        }
    },{ once: true })
}
