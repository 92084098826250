import JustValidate from 'just-validate'

const validateErrors = {
    required: 'Поле обязательно для заполнения',
    email: 'Введите корректный e-mail'
}

const forms = document.querySelectorAll('[data-form]')
if(forms.length > 0){
    forms.forEach(form => {
        let validator = new JustValidate(form)
        validator
            .addField(form.querySelector('[data-name]'), [
                {
                    rule: 'required',
                    errorMessage: validateErrors.required
                }
            ])
            .addField(form.querySelector('[data-email]'), [
                {
                    rule: 'required',
                    errorMessage: validateErrors.required
                }
            ])
            .addField(form.querySelector('[data-phone]'), [
                {
                    rule: 'required',
                    errorMessage: validateErrors.required
                }
            ])
            .addField(form.querySelector('[data-type]'), [
                {
                    rule: 'required',
                    errorMessage: validateErrors.required
                }
            ])
            .onSuccess(e => {
                e.preventDefault()
                const form = e.target
                const action = form.getAttribute("action");
                let facts = new FormData(form);
                fetch(action, {
                    method : "POST",
                    body: facts,
                    headers: {
                        "X-Requested-With":"XMLHttpRequest"
                    }
                }).then(response => {
                    form.remove()
                    document.querySelector('.modal__header-heading').innerText = 'Благодарим за обращение!'
                    document.querySelector('.modal__text').innerText = 'В ближайшее время с вами свяжется наш менеджер.'
                })
            })
    })
}

const formSelects = document.querySelectorAll('.form__select')
if(formSelects.length > 0){
    formSelects.forEach(el => {
        let button = el.querySelector('.form__select-button')
        button.addEventListener('click', () => {
            el.classList.toggle('opened')
            el.querySelector('.form__select-dropdown').classList.toggle('opened')
        })

        el.querySelectorAll('li').forEach(li => {
            li.addEventListener('click', () => {
                el.classList.toggle('opened')
                li.closest('.form__select-dropdown').classList.remove('opened')
                el.classList.add('selected')
                button.innerText = li.querySelector('label').innerText
            })
        })
    })
}

document.querySelectorAll('[type="tel"]').forEach(el => {
    el.addEventListener('input', e => {
        let r = '+'
        let phone = e.target.value.replace(/\D/g, "")
        if(phone === '') return e.target.value = '+7'

        let prefix = phone.charAt(0)
        if(prefix !== '7') prefix = '7'
        phone = phone.substring(1)
        r += `${prefix}`
        if(phone.length > 0) r += ` ${phone.substring(0, 3)}`
        if(phone.length > 3) r += ` ${phone.substring(3, 6)}`
        if(phone.length > 6) r += `-${phone.substring(6, 8)}`
        if(phone.length > 8) r += `-${phone.substring(8, 10)}`

        return e.target.value = r
    })
})