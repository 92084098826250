const tabsList = document.querySelectorAll('.product__tabs .item, .points__filter-buttons .item, .site-tabs__list .item')
if(tabsList.length > 0){
    tabsList.forEach(el => {
        const tab = el.dataset?.tab ?? null
        if(tab){
            el.addEventListener('click', () => {
                document.querySelectorAll('[data-tab]').forEach(t => t.classList.remove('selected'))
                document.querySelectorAll(`[data-tab="${tab}"]`).forEach(t => t.classList.add('selected'))
            })
        }
    })
}